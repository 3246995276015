// src/hooks/useTexts.js
import { useTranslation } from "react-i18next";

const useTexts = (page) => {
  const { t } = useTranslation(page);

  const texts = t;

  return {
    texts,
  };
};

export default useTexts;
