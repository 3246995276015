// src/utils/storage.js

import { set as idbSet, get as idbGet } from 'idb-keyval';

/**
 * Verifica si localStorage está disponible y funcionando.
 * @returns {boolean}
 */
const isLocalStorageAvailable = () => {
    const testKey = '__storage_test__';
    try {
        window.localStorage.setItem(testKey, testKey);
        window.localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * Almacena un valor utilizando localStorage o IndexedDB con timestamp.
 * @param {string} key
 * @param {any} value
 * @returns {Promise<void>}
 */
export const setItem = async (key, value) => {
    const dataToStore = {
        value,
        timestamp: Date.now(),
    };

    if (isLocalStorageAvailable()) {
        try {
            window.localStorage.setItem(key, JSON.stringify(dataToStore));
        } catch (e) {
            console.warn('Error al usar localStorage, cambiando a IndexedDB:', e);
            await idbSet(key, dataToStore);
        }
    } else {
        await idbSet(key, dataToStore);
    }
};

/**
 * Obtiene un valor utilizando localStorage o IndexedDB.
 * @param {string} key
 * @returns {Promise<any>}
 */
export const getItem = async (key, maxAgeInMs = 60 * 60 * 1000) => { // 1 hora por defecto
    if (isLocalStorageAvailable()) {
        try {
            const item = window.localStorage.getItem(key);
            if (item) {
                const parsedItem = JSON.parse(item);
                if (Date.now() - parsedItem.timestamp < maxAgeInMs) {
                    return parsedItem.value;
                }
                // Expirado, eliminar
                window.localStorage.removeItem(key);
                return null;

            }
            return null;
        } catch (e) {
            console.warn('Error al usar localStorage, cambiando a IndexedDB:', e);
            const idbItem = await idbGet(key);
            if (idbItem && Date.now() - idbItem.timestamp < maxAgeInMs) {
                return idbItem.value;
            }
            return null;
        }
    } else {
        const idbItem = await idbGet(key);
        if (idbItem && Date.now() - idbItem.timestamp < maxAgeInMs) {
            return idbItem.value;
        }
        return null;
    }
};

/**
 * Elimina un valor utilizando localStorage o IndexedDB.
 * @param {string} key
 * @returns {Promise<void>}
 */
export const removeItem = async (key) => {
    if (isLocalStorageAvailable()) {
        try {
            window.localStorage.removeItem(key);
        } catch (e) {
            console.warn('Error al usar localStorage, cambiando a IndexedDB:', e);
            // idb-keyval no tiene una función para eliminar, pero podemos sobrescribir el valor con null
            await idbSet(key, null);
        }
    } else {
        await idbSet(key, null);
    }
};
