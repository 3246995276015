  // axiosInstance.js
  import axios from 'axios';

  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api` || 'https://back.thestablegame.com/api',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    },
  });
  // console.log("AxiosInstace reached");
  export default axiosInstance;
