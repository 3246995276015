// ConfirmationScreen.js

import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import {
  PButtonPure,
  PDivider,
  PText,
  PSpinner,
  PInlineNotification,
} from "@porsche-design-system/components-react";
import useTexts from "MainApp/assets/utils/hooks/useTexts";
import { DateTime } from "luxon";
import { loadStripe } from "@stripe/stripe-js";
import "./ConfirmationScreen.css";
import BlurFade from "@/components/magicui/blur-fade";
import {
  dropShadowLowStyle,
  spacingFluidLarge,
  spacingFluidMedium,
  spacingFluidSmall,
  spacingFluidXLarge,
  spacingFluidXSmall,
  textXLargeStyle,
} from "@porsche-design-system/components-js/styles";
import RenderHumanDataFields from "./RenderHumanDataFields/RenderHumanDataFields";
import PersonalInfoFields from "./PersonalInfoFields/PersonalInfoFields";
import StickyDrawer from "Components/StickyDrawer/StickyDrawer";
import { tileBaseStyleDrop } from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import TuktukModelsSection from "./TuktukModelsSection/TuktukModelsSection";
import RenderStaticData from "./RenderStaticData/RenderStaticData";

import axiosInstance2 from "axiosConfig";
import { AuthContext } from "MainApp/assets/utils/context/AuthContext";

const ConfirmationScreen = ({
  date,
  time,
  onBack,
  formData,
  setFormData,
  isPhone,
  tourDuration,
  slug,
}) => {
  const { axiosInstance } = useContext(AuthContext);
  const textOrigin = "confirmationScreen";
  const { texts } = useTexts(textOrigin);
  const phoneRef = useRef(null);
  const countryCodeRef = useRef(null);

  // Estados
  const [hasMinors, setHasMinors] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [minors, setMinors] = useState([]);
  const [minorsTotal, setMinorsTotal] = useState(0);
  const [adults, setAdults] = useState(1);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const [tuktukModels, setTuktukModels] = useState([]);

  // Estado para errores de componentes hijos
  const [errors, setErrors] = useState({});

  // Estado para Stripe
  const [stripeKey, setStripeKey] = useState(null);

  // Memoizar stripePromise
  const stripePromise = useMemo(() => {
    return stripeKey ? loadStripe(stripeKey) : null;
  }, [stripeKey]);

  // Función para obtener CSRF Token
  const getCsrfToken = async () => {
    try {
      const response = await axiosInstance2.get("/csrf-token");
      return response.data.csrf_token;
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      throw error;
    }
  };

  // Obtener Stripe Publishable Key
  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await axiosInstance2.get(`/getStripeToken/${slug}`);
        // console.log(response);
        // console.log(response.data);
        // console.log(response.data.stripePublishableKey);
        const fetchedStripeKey = response.data; // Asegúrate de que la respuesta contenga el key correcto
        setStripeKey(fetchedStripeKey);
      } catch (error) {
        console.error("Error fetching Stripe publishable key:", error);
      }
    };

    fetchStripeKey();
  }, [slug]);

  // Function to handle errors from child components
  const handleFieldErrors = (fieldErrors) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  // Function to add minutes to epoch time
  function addMinutesToEpoch(minutes, epochTime) {
    const millisecondsToAdd = minutes * 60 * 1000;
    const newEpochTime = epochTime + millisecondsToAdd;
    setFormData((prevFormData) => ({
      ...prevFormData,
      bookingEnd: newEpochTime,
    }));
  }

  useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, hasMinors: hasMinors }));
  }, [hasMinors]);

  useEffect(() => {
    addMinutesToEpoch(tourDuration, formData.bookingStart);
  }, [formData.bookingStart]);

  useEffect(() => {
    const bookingStart = DateTime.fromISO(`${date}T${time}`, {
      zone: "Europe/Madrid",
    }).toSeconds();
    setFormData((prevFormData) => ({
      ...prevFormData,
      bookingStart,
    }));
  }, [date, time, setFormData]);

  // Update total persons
  useEffect(() => {
    const newPersons = adults + minorsTotal;
    setFormData((prevFormData) => ({
      ...prevFormData,
      adults: adults,
      persons: newPersons,
    }));
  }, [adults, minorsTotal, setFormData]);

  // Handle appointment and payment
  const handleConfirmAndSetAppointment = () => {
    handleStripeCheckout();
  };

  const handlePhoneChange = (countryCode, phoneNumber) => {
    const combinedPhone = [countryCode, phoneNumber].filter(Boolean).join(" ");
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: combinedPhone,
      countryCode,
    }));
  };

  const handleStripeCheckout = async () => {
    setIsProcessingPayment(true);
    // console.log("stripeKey:", stripeKey);
    const stripe = await stripePromise;
    // console.log("stripe:", stripe);
    const csrfToken = await getCsrfToken();

    // Concatenate name before the request
    const fullName = `${formData.firstName || ""} ${
      formData.lastName || ""
    }`.trim();

    try {
      const response = await axiosInstance.post(
        "/checkout",
        {
          name: fullName,
          email: formData.email,
          phone: formData.phone,
          country: formData.country,
          sprice: formData.priceUnit,
          totalUnits: formData.totalUnits,
          tuktukModels: formData.tuktukModels,
          origin_url: window.location.href,
          bookingEnd: formData.bookingEnd,
          bookingStart: formData.bookingStart,
          tourUUID: formData.tourUUID,
          customPickup: formData.customPickup,
          persons: formData.persons,
          adults: formData.adults,
          minorsTotal: formData.minorsTotal,
          minors: formData.minors,
          hasMinors: formData.hasMinors,
          minors2to5: formData.minors2to5,
          minors6to10: formData.minors6to10,
          minors11to17: formData.minors11to17,
        },
        {
          headers: { "X-CSRF-TOKEN": csrfToken },
        }
      );

      // console.log("response data", response.data);
      // console.log("response", response);
      // console.log("Pre session");
      const session = response.data;
      // console.log(session.id);
      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        console.error(result.error.message);
      }
      // console.log("finalized");
    } catch (error) {
      console.error("Error creating checkout session:", error);
      if (error.response?.data?.errors) {
        setValidationMessages(error.response.data.errors);
      } else if (error.response?.data?.error) {
        setValidationMessages({ apiError: error.response.data.error });
      } else {
        setValidationMessages({ apiError: "An unexpected error occurred" });
      }
    } finally {
      setIsProcessingPayment(false);
    }
  };

  // Add minor
  const handleAddMinor = () => {
    setMinors([...minors, { age: 2 }]);
    setMinorsTotal((prevTotal) => prevTotal + 1);
    setHasMinors(true);

    setFormData((prevFormData) => ({
      ...prevFormData,
      minorsTotal: minorsTotal + 1,
      minors2to5: (prevFormData.minors2to5 || 0) + 1,
    }));
  };

  // Remove minor
  const handleRemoveMinor = (index) => {
    const updatedMinors = minors.filter((_, i) => i !== index);
    setMinors(updatedMinors);
    setMinorsTotal(updatedMinors.length);
    const categories = updateMinorCategories(updatedMinors);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...categories,
      minorsTotal: updatedMinors.length,
      hasMinors: updatedMinors.length > 0,
    }));

    setValidationMessages((prev) => {
      const updatedMessages = { ...prev };
      delete updatedMessages[`minor-${index}`];
      return updatedMessages;
    });
  };

  // Update minor categories based on age
  const updateMinorCategories = (minorsList) => {
    let minors2to5 = 0;
    let minors6to10 = 0;
    let minors11to17 = 0;

    minorsList.forEach((minor) => {
      const age = parseInt(minor.age, 10);
      if (age >= 2 && age <= 5) {
        minors2to5 += 1;
      } else if (age >= 6 && age <= 10) {
        minors6to10 += 1;
      } else if (age >= 11 && age <= 17) {
        minors11to17 += 1;
      }
    });

    return { minors2to5, minors6to10, minors11to17 };
  };

  // Handle minor age change
  const handleMinorAgeChange = (index, value) => {
    const age = parseInt(value, 10);

    if ((age >= 2 && age <= 17) || value === "") {
      const updatedMinors = [...minors];
      updatedMinors[index].age = value;
      setMinors(updatedMinors);

      const categories = updateMinorCategories(updatedMinors);
      setFormData((prevFormData) => ({
        ...prevFormData,
        minorsTotal: updatedMinors.length,
        minors2to5: categories.minors2to5,
        minors6to10: categories.minors6to10,
        minors11to17: categories.minors11to17,
      }));

      setValidationMessages((prev) => {
        const updatedMessages = { ...prev };
        delete updatedMessages[`minor-${index}`];
        return updatedMessages;
      });
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [`minor-${index}`]: "La edad debe estar entre 2 y 17 años.",
      }));
    }
  };

  // Synchronize minors with formData
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      minors: minors.map((minor) => ({ age: minor.age })),
      minorsTotal: minors.length,
    }));
  }, [minors, setFormData]);

  // Calculate total price
  const calculateTotalPrice = () => {
    if (formData.priceUnit && formData.tuktukModels) {
      const totalUnits = formData.tuktukModels.reduce(
        (acc, model) => acc + model.units,
        0
      );
      setTotalPrice(formData.priceUnit * totalUnits);
    }
  };

  // Load tuktuk models
  useEffect(() => {
    const fetchTuktukModels = async (tourUUID, bookingStart) => {
      try {
        const params = { tourUUID, bookingStart };
        const response = await axiosInstance.get("/tuktuksAPI/get-simple", {
          params,
        });
        if (Array.isArray(response.data)) {
          const models = response.data.map((tuktuk) => ({
            value: tuktuk.tuktukUUID,
            label: tuktuk.model,
            availableUnits: tuktuk.units,
            maxPeople: tuktuk.maxPeople,
          }));
          setTuktukModels([
            { value: "", label: "Seleccionar un modelo" },
            ...models,
          ]);
          setFormData((prevFormData) => ({
            ...prevFormData,
            tuktukModels: [{ value: "", units: 1 }],
          }));
        }
      } catch (error) {
        console.error("Error fetching tuktuk models:", error);
      }
    };

    if (formData.tourUUID && formData.bookingStart) {
      fetchTuktukModels(formData.tourUUID, formData.bookingStart);
    }
  }, [formData.tourUUID, formData.bookingStart, setFormData]);

  useEffect(() => {
    calculateTotalPrice();
  }, [formData.tuktukModels, formData.priceUnit]);

  // Add tuktuk model
  const addTuktukModel = () => {
    const newTuktukModels = [...formData.tuktukModels, { value: "", units: 1 }];
    setFormData({ ...formData, tuktukModels: newTuktukModels });
  };

  // Remove tuktuk model
  const removeTuktukModel = (index) => {
    const newModels = formData.tuktukModels.filter((_, i) => i !== index);
    setFormData({ ...formData, tuktukModels: newModels });
  };

  // Check if the form is incomplete
  const isFormIncomplete = () => {
    // Check for any errors
    if (Object.values(errors).some((error) => error)) {
      return true;
    }

    // Other validation logic
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      formData.phone.length < 6 ||
      !formData.country
    ) {
      return true;
    }

    if (!formData.persons || formData.persons <= 0) {
      return true;
    }

    if (
      formData.tuktukModels.some((model) => !model.value || model.units <= 0)
    ) {
      return true;
    }

    if (!tuktukModels || tuktukModels.length === 0) {
      return true;
    }

    // Calculate total capacity
    const totalCapacity = formData.tuktukModels.reduce((acc, model) => {
      const tuktuk = tuktukModels.find((t) => t.value === model.value);
      return acc + (tuktuk ? tuktuk.maxPeople * model.units : 0);
    }, 0);

    if (totalCapacity < formData.persons) return true;
    return false;
  };

  return (
    <>
      <div className="confirmationScreen">
        <PButtonPure
          className="confirmationBackButton"
          icon="arrow-left"
          hideLabel={true}
          onClick={onBack}
          style={{ marginLeft: isPhone ? "" : spacingFluidXSmall }}
        />
        <div
          className="conditionalContainer"
          style={{ display: isPhone ? "flex" : "grid" }}
        >
          {/* Dynamic Section */}
          <div
            className="leftConfirmationScreen"
            style={{
              padding: isPhone
                ? spacingFluidSmall
                : `0 ${spacingFluidLarge} ${spacingFluidXLarge} ${spacingFluidXLarge}`,
              margin: isPhone
                ? `0 ${spacingFluidSmall} ${spacingFluidLarge} ${spacingFluidSmall}`
                : "",
              gap: isPhone ? spacingFluidLarge : spacingFluidMedium,
            }}
          >
            {!isPhone && <PDivider direction="vertical" className="vDivider" />}

            <BlurFade className="z-0">
              <PText
                size="x-large"
                weight={isPhone ? "bold" : ""}
                style={{ marginBottom: spacingFluidMedium }}
              >
                {texts("form.headers.bookData")}
              </PText>

              <RenderHumanDataFields
                adults={adults}
                setAdults={setAdults}
                handleMinorAgeChange={handleMinorAgeChange}
                handleRemoveMinor={handleRemoveMinor}
                minors={minors}
                setMinors={setMinors}
                textOrigin={textOrigin}
                textBranch="form.humanData"
                handleAddMinor={handleAddMinor}
                headerText={texts("form.headers.passengers")}
              />
            </BlurFade>
            <BlurFade className="relative">
              <div
                className="flex flex-col"
                style={{
                  gap: isPhone ? spacingFluidLarge : spacingFluidMedium,
                }}
              >
                <TuktukModelsSection
                  addTuktukModel={addTuktukModel}
                  removeTuktukModel={removeTuktukModel}
                  formData={formData}
                  setFormData={setFormData}
                  tuktukModels={tuktukModels}
                  adults={adults}
                  persons={formData.persons}
                  validationMessages={validationMessages}
                  textOrigin={textOrigin}
                  textBranch="form.modelData"
                  textsExtra={texts("form.headers.extra.passengers")}
                  textsHeader={texts("form.headers.vehicles")}
                  setErrors={setErrors}
                  errors={errors}
                />

                <PersonalInfoFields
                  formData={formData}
                  setFormData={setFormData}
                  phoneRef={phoneRef}
                  countryCodeRef={countryCodeRef}
                  onPhoneChange={handlePhoneChange}
                  textOrigin={textOrigin}
                  textBranch="form.personalData"
                  phoneTexts={"form.personalData.phone"}
                  textHeader={texts("form.headers.personal")}
                  isPhone={isPhone}
                  onError={handleFieldErrors}
                />
              </div>
            </BlurFade>
          </div>

          {/* Static Section */}
          {!isPhone && (
            <BlurFade className="h-full">
              <div
                className="rightConfirmationScreen"
                style={{ padding: spacingFluidSmall }}
              >
                <RenderStaticData
                  titleStyle={textXLargeStyle}
                  time={time}
                  date={date}
                  totalPrice={totalPrice}
                  formData={formData}
                  isFormIncompleteProp={isFormIncomplete()}
                  handleConfirmAndSetAppointment={
                    handleConfirmAndSetAppointment
                  }
                  texts={texts}
                  currency="€"
                />
              </div>
            </BlurFade>
          )}
        </div>

        {validationMessages && (
          <div className="error-messages">
            {Object.entries(validationMessages).map(([field, messages]) => (
              <PInlineNotification key={field}>
                {texts("paymentIntent.error")}
              </PInlineNotification>
            ))}
          </div>
        )}
      </div>
      {isPhone && (
        <StickyDrawer
          price={totalPrice}
          currency="€"
          isProcessingPayment={isProcessingPayment}
          spinnerText={texts("paymentIntent.processing")}
        >
          <RenderStaticData
            titleStyle={textXLargeStyle}
            time={time}
            date={date}
            totalPrice={totalPrice}
            formData={formData}
            isFormIncompleteProp={isFormIncomplete()}
            handleConfirmAndSetAppointment={handleConfirmAndSetAppointment}
            texts={texts}
            currency="€"
            isPhone={isPhone}
          />
        </StickyDrawer>
      )}
      {isProcessingPayment && (
        <div className="spinnerOverlay">
          <div
            className="spinnerContainer"
            style={{
              ...tileBaseStyleDrop,
              ...dropShadowLowStyle,
              padding: spacingFluidMedium,
            }}
          >
            <PSpinner />
            <PText>{texts("paymentIntent.processing")}</PText>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationScreen;
