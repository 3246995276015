import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './App.css';
import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react';

import SuccessScreen from 'MainApp/Pages/StripePayment/Success';
import NotFound from 'MainApp/Pages/404/NotFound';
import TourSelection from 'MainApp/Pages/TourSelection/TourSelection';
import CancelScreen from 'MainApp/Pages/StripePayment/Cancel';
import TourInformation from 'MainApp/Pages/TourInformation/TourInformation';
import useIsPhone from 'MainApp/assets/utils/hooks/useIsPhone';
import TourContainer from 'Components/TourContainer/TourContainer';
import TourDaySelection from 'MainApp/Pages/TourDaySelection/TourDaySelection';
import { LanguageProvider } from 'MainApp/assets/utils/context/LanguageContext';
import { ToursProvider } from 'MainApp/assets/utils/context/TourContext';
import Son from 'pages/Son';
// import { AuthContext } from 'MainApp/assets/utils/context/AuthContext';
// import Dashboard from 'pages/Dashboard';

const pageVariants = {
    out_FadeOut: {
        opacity: 0,
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },
    initial: {
        opacity: 0,
        x: '100vw'
    },
    in: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },
    out: {
        opacity: 0,
        x: '-100vw',
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },

    slideInFromRight: {
        opacity: 0,
        x: '100vw',
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },
    slideOutToLeft: {
        opacity: 0,
        x: '-100vw',
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },
    slideOutToRight: {
        opacity: 0,
        x: '100vw',
        transition: {
            duration: 0.5,
            delay: 0.3
        }
    },

    generalOut: {
        opacity: 0,
        transition: {
            duration: 0.6,
            // delay: 0.3
        }
    },
    generalInitial: {
        opacity: 1
    },
};

const pageTransition = {
    type: 'tween',
    duration: 0.5
};

const MotionRoute = ({ element, initialVariants, exitVariants }) => (
    <motion.div
        initial={initialVariants ? initialVariants : "initial"}
        animate="in"
        exit={exitVariants ? exitVariants : "out"}
        variants={pageVariants}
        transition={pageTransition}
        className="motionContainerStyle"

    >
        {element}
    </motion.div>
);

const App = () => {

    // const { token, setToken, setOriginUrl } = useContext(AuthContext);
    // const [ready, setReady] = useState(false);
    // console.log("Test1");

    // useEffect(() => {
    //     window.parent.postMessage({ action: "iframeReady" }, "*");

    //     console.log("Test2");
    //     const handleMessage = async (event) => {
    //         // console.log("Message event received");

    //         const { action, payload, origin } = event.data; // Obtener acción y payload
    //         if (action === "updateToken") {
    //             console.log("Received token payload:", payload);

    //             setToken(payload);
    //             console.log("token val");
    //             console.log(token);
    //             setOriginUrl(origin);
    //             console.log("origin val");
    //             console.log(origin);

    //             // try {
    //             //     // Test de autenticación con el token recibido
    //             //     const response = await axiosInstance.get('/test-auth');
    //             //     setUser(response.data.user);
    //             //     setReady(true);
    //             // } catch (error) {
    //             //     console.error('Error al obtener datos de usuario:', error);
    //             // }
    //         }
    //         // console.log("mensaje escuchado");
    //     };

    //     // Escuchar eventos de mensaje
    //     window.addEventListener('message', handleMessage);

    //     return () => {
    //         window.removeEventListener('message', handleMessage);
    //     };
    // }, [setToken]);



    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location);
    const [direction, setDirection] = useState('forward');
    const isPhone = useIsPhone();

    useEffect(() => {
        if (location.pathname.includes('/tour/')) {
            if (prevLocation.pathname.includes('/tour/') && location.pathname !== prevLocation.pathname) {
                setDirection('forward');
            } else if (location.pathname === '/') {
                setDirection('backward');
            } else {
                setDirection('forward');
            }
        } else if (prevLocation.pathname.includes('/tour/') && location.pathname !== '/') {
            setDirection('forward');
        }
        setPrevLocation(location);
    }, [location, prevLocation]);

    const getVariants = () => {
        if (location.pathname.includes('/tour/')) {
            if (direction === 'forward') {
                return { initial: 'slideInFromRight', exit: 'slideOutToLeft' };
            } else if (direction === 'backward') {
                return { initial: 'slideInFromRight', exit: 'slideOutToRight' };
            }
        }
        return { initial: 'initial', exit: 'out' };
    };

    const getCurrentScreen = () => {
        if (location.pathname === '/') {
            return 'TourSelection';
        } else if (location.pathname.includes('/tour/')) {
            return 'TourInformation';
        }
        return 'Other';
    };

    return (
        <TourContainer screen={getCurrentScreen()}>
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    {/* <Route exact path="/" component={Dashboard} /> */}

                    <Route exact
                        path="/"
                        element={<MotionRoute
                            element={<TourSelection />}
                            initialVariants="generalOut"
                            exitVariants="generalOut"
                        />}
                    />
                    <Route exact
                        path="/tour/details/:slug"
                        element={<MotionRoute
                            element={<TourInformation isPhone={isPhone} />}
                            initialVariants="generalOut"
                            exitVariants="generalOut"
                        />}
                    />
                    <Route exact
                        path="/reservation/:title"
                        element={<MotionRoute
                            element={<TourDaySelection />}
                            initialVariants="generalOut"
                            exitVariants="generalOut"
                        />}
                    />
                    <Route exact
                        path="/reservation/cancel"
                        element={<MotionRoute
                            element={<CancelScreen />}
                            initialVariants={getVariants().initial}
                            exitVariants={getVariants().exit}
                        />}
                    />
                    <Route exact
                        path="/reservation/success"
                        element={<MotionRoute
                            element={<SuccessScreen />}
                            initialVariants={getVariants().initial}
                            exitVariants={getVariants().exit}
                        />}
                    />
                    <Route exact path="/son" element={<Son />} />
                    <Route path="*" element={<NotFound />} />
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                </Routes>
            </AnimatePresence>
        </TourContainer>
    );
};

const AppWrapper = () => (
    <Router>
        <PorscheDesignSystemProvider>
            <LanguageProvider>
                <ToursProvider>
                    <App />
                </ToursProvider>
            </LanguageProvider>
        </PorscheDesignSystemProvider>
    </Router>
);

export default AppWrapper;
