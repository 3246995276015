import { useContext, useEffect } from 'react';
import { AuthContext } from 'MainApp/assets/utils/context/AuthContext';
import axiosInstance from 'axiosInstance';

const useAxios = () => {
  const { token, logout } = useContext(AuthContext);

  // Interceptor para agregar token
  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          console.error('Token inválido o expirado. Cerrando sesión...');
          logout(); // Deslogea al usuario
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [token, logout]);

  return axiosInstance;
};

export default useAxios;
