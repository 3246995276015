import axios from 'axios';
import React, { createContext, useState, useEffect, useMemo } from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useState(
        "18|MbeegzGzSuu7VQUFuR1F3a88aMKWNTSgJE4C7vKz0b1884bc"
    );
    const [originUrl, setOriginUrl] = useState('https://demo.thestablegame.com');
    const [realToken, setRealToken] = useState('');
    const [user, setUser] = useState(null);
    const [isAuthReady, setIsAuthReady] = useState(false);
    // const [axiosInstance, setAxiosInstance] = useState(null);

    const logout = () => {
        // console.log("Value before delete:");
        // console.log(`This token is ${token}`);
        setToken('');
        setUser(null);
    };

    useEffect(() => {
        // Simulando carga de token desde localStorage u otro lado
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            setToken(storedToken);
        }
        // Indicar que la autenticación terminó de inicializar
        setIsAuthReady(true);
    }, []);

    useEffect(() => {
        if (token) {
            setRealToken(`Bearer ${token}`);
            // console.log("Token value updated!");
            // console.log(`This token is ${token}`);
        } else {
            setRealToken(null);
            // console.log("Token value dropped!");
            // console.log(`This token is ${token}`);
        }
    }, [token]);

    // useEffect(() => {
    //     if (realToken) {
    //         // Crear instancia solo cuando hay realToken
    //         const instance = axios.create({
    //             baseURL: `${process.env.REACT_APP_API_BASE_URL}/api` || 'https://back.thestablegame.com/api',
    //             withCredentials: true,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': realToken,
    //             },
    //         });
    //         setAxiosInstance(instance);
    //         console.log("Axios instance created with Authorization header:", realToken);
    //     } else {
    //         setAxiosInstance(null);
    //         console.log("Axios instance cleared.");
    //     }
    // }, [realToken]);

    const axiosInstance = useMemo(() => {
        if (!realToken) return null;

        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL
                ? `${process.env.REACT_APP_API_BASE_URL}/api`
                : 'https://back.thestablegame.com/api',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-Origin-Domain': originUrl,
                'Authorization': realToken,
            },
        });
    }, [realToken]);

    return (
        <AuthContext.Provider value={{ token, setToken, user, setUser, logout, axiosInstance, isAuthReady, realToken, setOriginUrl }}>
            {children}
        </AuthContext.Provider>
    );
};
